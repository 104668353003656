import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import PeCurriculum from "../../components/programs/pecurriculum"

const PECurriculumPage = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | Programs | PE Curriculum">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="PE Curriculum" crumbTitle="PE Curriculum" />
      <PeCurriculum showbutton={false} top={true}/>
    </div>
    <Footer />
  </Layout>
);

export default PECurriculumPage;
